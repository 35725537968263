import { ISurveyCycle, ISurveyParticipant } from '@shared/surveys';
import { PageContent } from '@web/app/Page';
import { useApi } from '@web/common/useApi';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Max, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { QuestionResponses } from '@web/questions/QuestionResponses';
import { Skeleton } from 'antd';
import { sortBy } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';

interface ISurveyDetails {
  surveyCycle: ISurveyCycle;
  participant: ISurveyParticipant;
}

export const ViewSurveyPage: React.FC = () => {
  const { surveyCycleToken } = useParams();
  const { data: surveyDetails, error } = useApi<ISurveyDetails>(
    `/survey-cycles/${surveyCycleToken}/details`,
  );

  if (error) {
    return (
      <ErrorPageContent
        title="Survey not found"
        subTitle="Sorry, we could not find this survey. Please try another page."
      />
    );
  }

  if (!surveyDetails?.surveyCycle) {
    return (
      <PageContent>
        <PageHeader
          title={surveyDetails?.surveyCycle.name ?? ''}
          mobileTitle={surveyDetails?.surveyCycle.name ?? ''}
          navigateBack
          defaultNavigateBackTo="/requests"
        />
        <Skeleton />
      </PageContent>
    );
  }

  const sortedQuestions = sortBy(
    surveyDetails.surveyCycle.questions,
    'position',
  );
  return (
    <PageContent>
      <PageHeader
        title={surveyDetails?.surveyCycle.name ?? ''}
        mobileTitle={surveyDetails?.surveyCycle.name ?? ''}
        navigateBack
        defaultNavigateBackTo="/requests"
      />
      <Pane>
        <Max width="800px">
          <Text style={{ fontStyle: 'italic' }}>
            {surveyDetails.surveyCycle.anonymous
              ? 'This survey is anonymous.'
              : 'Your responses will be shared with your manager.'}
          </Text>
          <Spacer />
          <QuestionResponses
            questions={sortedQuestions}
            responses={surveyDetails.participant.responses}
            hideNullResponses
          />
        </Max>
      </Pane>
    </PageContent>
  );
};
