import {
  DeleteOutlined,
  GlobalOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { addJournalEntry, updateJournalEntry } from '@client/ImpactsClient';
import { Feature } from '@shared/features';
import { IGoal } from '@shared/goals';
import { IImpact, ISuggestedEntry, SuggestedEntryType } from '@shared/impacts';
import { UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { del } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { useFeature } from '@web/common/useFeature';
import { useNavigateBack } from '@web/common/useNavigateBack';
import { useOutstandingCount } from '@web/common/useOutstandingCount';
import { FormButtons } from '@web/components/FormButtons';
import { MarkdownEditor } from '@web/components/MarkdownEditor';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { StopPropagation } from '@web/components/StopPropagation';
import { Column, PageGridLayout, Spacer } from '@web/components/layout';
import { Small, Text } from '@web/components/typography';
import { SelectUsers } from '@web/components/users/SelectUsers';
import { SelectGoalModal } from '@web/goals/SelectGoalModal';
import { ViewGoals } from '@web/goals/ViewGoals';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Skeleton,
  Switch,
  Tooltip,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { uniqBy } from 'lodash';
import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { LoadingDetails } from './LoadingDetails';
import {
  NewJournalEntryTour,
  dataTourCollaborators,
  dataTourDescription,
  dataTourRelatedGoals,
  dataTourSave,
  dataTourTitle,
} from './NewJournalEntryTour';
import { RecentEntries } from './RecentEntries';
import { SlackJournalEntryTour } from './SlackJournalEntryTour';
import { CreateFromPullRequestBanner } from './banners/CreateFromPullRequestBanner';

interface ImpactFields {
  title: string;
  descriptionMarkdown: string;
  collaboratorTokens: UserToken[];
  date: dayjs.Dayjs;
  isPublic: boolean;
}

export const EditEntryPage: React.FC = () => {
  const { booleanValue: journalRelatedGoalsEnabled } = useFeature(
    Feature.JOURNAL_RELATED_GOALS,
  );
  const { booleanValue: publicJournalEnabled } = useFeature(
    Feature.PUBLIC_JOURNAL_ENABLED,
  );
  const { isMobile } = useResponsive();
  const { reloadOutstandingTaskCount } = useOutstandingCount();
  const goBackOrGoTo = useNavigateBack();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { impactToken } = useParams();
  const [searchParams] = useSearchParams();
  const suggestion = searchParams.get('suggestion');
  const completion = searchParams.get('completion');
  const date = searchParams.get('date');
  const [showAddGoal, setShowAddGoal] = useState(false);
  const [relatedGoals, setRelatedGoals] = useState<IGoal[]>([]);

  const isEdit = !!impactToken;
  const { data: impact, mutate } = useApi<IImpact>(
    isEdit ? `/impacts/${impactToken}` : null,
  );
  const { data: loadedRelatedGoals, mutate: mutateRelatedGoals } = useApi<
    IGoal[]
  >(isEdit ? `/goals/entries/${impactToken}` : null);

  React.useEffect(() => {
    if (loadedRelatedGoals) {
      setRelatedGoals(loadedRelatedGoals);
    }
  }, [loadedRelatedGoals]);
  const { data: suggestedEntry } = useApi<ISuggestedEntry>(
    !isEdit && suggestion ? `/impacts/suggestions/${suggestion}` : null,
  );
  const {
    data: slackSuggestion,
    mutate: reloadSlackSuggestion,
    error: slackSuggestionError,
  } = useApi<ISuggestedEntry>(
    completion ? `/impacts/slack/entry/${completion}` : null,
  );
  const { data: suggestedCollaborators } = useApi(
    '/ona/suggested_collaborators',
  );
  const [generatingSuggestion, setGeneratingSuggestion] = React.useState(false);
  React.useEffect(() => {
    if (slackSuggestion && !slackSuggestion.title) {
      setGeneratingSuggestion(true);
      setTimeout(() => {
        void reloadSlackSuggestion();
      }, 1500);
    }
  }, [slackSuggestion]);

  const [form] = Form.useForm<ImpactFields>();
  const [isSaving, setIsSaving] = React.useState(false);
  const [isNewEntryTourActive, setIsNewEntryTourActive] = React.useState(false);
  const [githubSuggestedEntry, setGithubSuggestedEntry] =
    React.useState<Partial<ISuggestedEntry>>();

  const pageTitle = isEdit ? 'Edit journal entry' : 'New journal entry';
  const loadingInitialData =
    (isEdit && !impact) ||
    (!isEdit && suggestion && !suggestedEntry) ||
    (!isEdit && completion && !slackSuggestion?.title && !slackSuggestionError);
  if (loadingInitialData) {
    return (
      <PageContent>
        <PageHeader
          title={pageTitle}
          mobileTitle={pageTitle}
          navigateBack
          defaultNavigateBackTo="/journal"
        />
        <Pane>
          {generatingSuggestion ? (
            <LoadingDetails>Generating entry details...</LoadingDetails>
          ) : (
            <Skeleton />
          )}
        </Pane>
      </PageContent>
    );
  }

  const handleSave = async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);
    let impactFields: ImpactFields;
    try {
      impactFields = await form.validateFields();
    } catch (error) {
      // ignore validation errors and rely on inline error messages
      setIsSaving(false);
      return;
    }

    try {
      const impactAttrs: Partial<IImpact> = {
        suggestedEntryToken: suggestion ?? githubSuggestedEntry?.token,
        chatCompletionToken: completion,
        ...impactFields,
        contentType: 'text/markdown',
        date: impactFields.date.toDate(),
      };
      const relatedGoalTokens = relatedGoals.map((goal) => goal.token);

      let savedImpact: IImpact;
      if (isEdit) {
        savedImpact = await updateJournalEntry(
          impact.token,
          impactAttrs,
          impactFields.collaboratorTokens,
          relatedGoalTokens,
        );
      } else {
        savedImpact = await addJournalEntry(
          impactAttrs,
          impactFields.collaboratorTokens,
          relatedGoalTokens,
        );
        if (suggestion) {
          void reloadOutstandingTaskCount();
        }
      }
      void message.success('Entry saved');
      form.resetFields();
      await mutate();
      await mutateRelatedGoals();
      if (isEdit) {
        navigate(-1);
      } else {
        navigate(`/journal/${savedImpact.token}`, { replace: true });
      }
    } catch (error) {
      void message.error('Error');
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    try {
      await del(`/impacts/${impact.token}`);
      void message.success('Entry removed');
      navigate('/journal');
    } catch (error) {
      void message.error('Error');
    }
  };

  const handlePullRequestSuggestion = (
    suggestedEntry: Partial<ISuggestedEntry>,
  ) => {
    form.setFieldValue('title', suggestedEntry.title);
    form.setFieldValue('date', dayjs(suggestedEntry.date));
    form.setFieldValue('descriptionMarkdown', suggestedEntry.description);
    form.setFieldValue(
      'collaboratorTokens',
      suggestedEntry.collaborators?.map((user) => user.token),
    );
    setGithubSuggestedEntry(suggestedEntry);
  };

  const handleRemoveGoal = (toRemove: IGoal) => {
    setRelatedGoals(relatedGoals.filter((g) => g.token !== toRemove.token));
  };

  let initialValues: Partial<ImpactFields> = {
    date: dayjs(date ? new Date(parseInt(date)) : new Date()),
  };
  const initialCollaborators = (
    impact?.collaborators ??
    suggestedEntry?.collaborators ??
    slackSuggestion?.collaborators ??
    githubSuggestedEntry?.collaborators ??
    []
  ).filter((collaborator) => collaborator.token !== user.token);
  const collaboratorTokens = initialCollaborators.map((c) => c.token);
  if (impact) {
    initialValues = {
      date: dayjs(impact.date),
      title: impact.title,
      descriptionMarkdown: impact.descriptionMarkdown,
      collaboratorTokens,
      isPublic: impact.isPublic,
    };
  } else if (suggestedEntry || slackSuggestion) {
    const suggestion = suggestedEntry ?? slackSuggestion;
    initialValues = {
      date: dayjs(new Date(suggestion.date ?? Date.now())),
      title: suggestion.title,
      // TODO need to migration old suggestions to markdown
      descriptionMarkdown: suggestion.description,
      collaboratorTokens,
    };
  }

  const titlePlaceholder =
    suggestedEntry?.type === SuggestedEntryType.MANAGER_TEAM_ENTRY
      ? 'e.g. something recent where you’d like upwards feedback from your team'
      : undefined;

  return (
    <PageContent>
      <PageHeader
        title={
          <span>
            {pageTitle}{' '}
            <Text style={{ display: 'inline', fontSize: 18 }}>
              <Tooltip title="Take a tour of this page">
                <GlobalOutlined
                  onClick={() => {
                    setIsNewEntryTourActive(true);
                  }}
                />
              </Tooltip>
            </Text>
          </span>
        }
        mobileTitle={pageTitle}
        description="Journal entries are great for capturing work that you are proud of, or things you spent time on, or topics where you want feedback."
        secondaryActions={
          isEdit
            ? [
                {
                  confirm: {
                    title: 'Are you sure you want to delete this entry?',
                    description:
                      'All comments and feedback will also be deleted. This cannot be undone.',
                  },
                  onClick: handleDelete,
                  icon: <DeleteOutlined />,
                  label: 'Delete',
                },
              ]
            : undefined
        }
        navigateBack
        defaultNavigateBackTo="/journal"
      />
      {!isEdit && !suggestion && (
        <CreateFromPullRequestBanner onSuggest={handlePullRequestSuggestion} />
      )}
      <Pane id="edit-entry-form-container">
        <PageGridLayout
          left={
            <Form
              form={form}
              initialValues={initialValues}
              name="basic"
              autoComplete="off"
              layout="vertical"
            >
              <Column>
                <Form.Item
                  data-tour={dataTourTitle}
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Title is required' }]}
                  shouldUpdate
                >
                  <Input
                    disabled={isSaving}
                    autoFocus
                    placeholder={titlePlaceholder}
                  />
                </Form.Item>
                <Form.Item
                  label="Date"
                  name="date"
                  rules={[{ required: true, message: 'Date is required' }]}
                >
                  <DatePicker disabled={isSaving} />
                </Form.Item>
                <Form.Item
                  data-tour={dataTourDescription}
                  label="Description"
                  name="descriptionMarkdown"
                  rules={[
                    { required: true, message: 'Description is required' },
                  ]}
                  shouldUpdate
                >
                  <MarkdownEditor />
                </Form.Item>
                {journalRelatedGoalsEnabled && (
                  <Column data-tour={dataTourRelatedGoals}>
                    <ViewGoals
                      goals={relatedGoals}
                      title={'Related Goals'}
                      startEmptyExpanded
                      emptyText="No related goals"
                      hideDueDate={isMobile}
                      extra={
                        <StopPropagation>
                          <Button
                            size="small"
                            type="text"
                            onClick={() => {
                              setShowAddGoal(true);
                            }}
                          >
                            <PlusOutlined
                              style={{ position: 'relative', top: -1 }}
                            />
                            Add
                          </Button>
                        </StopPropagation>
                      }
                      onRemove={handleRemoveGoal}
                      collapsable={false}
                    />
                    <Spacer size={24} />
                  </Column>
                )}
                <Form.Item
                  data-tour={dataTourCollaborators}
                  label="Share with others to get feedback"
                  name="collaboratorTokens"
                  valuePropName="userTokens"
                >
                  <SelectUsers
                    hideUsers={[user.token]}
                    initialUsers={initialCollaborators}
                    placeholder="e.g. peers, collaborators and stakeholders"
                    suggestions={suggestedCollaborators}
                  />
                </Form.Item>
                <InfoText>
                  This entry will also be shared with your manager.
                </InfoText>
                {publicJournalEnabled && (
                  <Form.Item
                    label="Public"
                    name="isPublic"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                )}
                <FormButtons>
                  <Button
                    data-tour={dataTourSave}
                    type="primary"
                    disabled={isSaving}
                    onClick={() => {
                      void handleSave();
                    }}
                  >
                    Save entry
                  </Button>
                  <Button
                    disabled={isSaving}
                    onClick={() => {
                      goBackOrGoTo('/journal');
                    }}
                  >
                    Cancel
                  </Button>
                </FormButtons>
              </Column>
            </Form>
          }
          right={
            isMobile ? null : (
              <RecentEntries
                userToken={user.token}
                title="Recent Entries"
                limit={5}
              />
            )
          }
        />
      </Pane>
      {completion && <SlackJournalEntryTour />}
      <NewJournalEntryTour
        onClose={() => {
          setIsNewEntryTourActive(false);
        }}
        open={isNewEntryTourActive}
      />
      {showAddGoal && (
        <SelectGoalModal
          title="Add Related Goal"
          instructions="Attach any goals related to this journal entry."
          onSave={async (goal) => {
            setRelatedGoals(uniqBy([...relatedGoals, goal], 'token'));
            setShowAddGoal(false);
          }}
          onCancel={() => {
            setShowAddGoal(false);
          }}
          okText="Add"
          omitGoals={relatedGoals.map((goal) => goal.token)}
        />
      )}
    </PageContent>
  );
};

const InfoText = styled(Small)`
  color: #777;
  position: relative;
  top: -24px;
`;
