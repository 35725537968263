import { QuestionType } from '@shared/QuestionType';
import { IQuestionResponse } from '@shared/question_response';
import {
  IQuestion,
  QuestionToken,
  isOpenEndedQuestion,
  isRatingQuestion,
} from '@shared/questions';
import { UserMapItem } from '@shared/types';
import { Column } from '@web/components/layout';
import { Header3 } from '@web/components/typography';
import { sortBy } from 'lodash';
import React from 'react';

import { InputRatingQuestion } from './InputRatingQuestion';
import { InputTextQuestion } from './InputTextQuestion';

export interface IInputQuestionsProps {
  questions: IQuestion[];
  responseMap: Omit<
    Map<QuestionToken, IQuestionResponse>,
    'set' | 'clear' | 'delete'
  >;
  onResponseChanged?: (
    question: IQuestion,
    response: IQuestionResponse,
  ) => void;
  onChange?: (response: IQuestionResponse) => void;
  receiver?: UserMapItem;
  showVisibilityOptions?: boolean;
  disabled?: boolean;
  errors?: Record<QuestionToken, string>;
}

export const InputQuestions: React.FC<IInputQuestionsProps> = ({
  questions,
  responseMap,
  onResponseChanged,
  onChange,
  receiver,
  showVisibilityOptions = false,
  disabled,
  errors,
}) => {
  const sortedQuestions = sortBy(questions, 'position');
  return (
    <Column style={{ flex: 1 }} gap={24}>
      {sortedQuestions.map((question, index) => (
        <InputQuestion
          key={question.token}
          question={question}
          response={responseMap.get(question.token) as any}
          onResponseChanged={onResponseChanged}
          onChange={onChange}
          receiver={receiver}
          autoFocus={index === 0}
          showVisibilityOptions={showVisibilityOptions}
          disabled={disabled}
          error={errors?.[question.token]}
        />
      ))}
    </Column>
  );
};

const InputQuestion: React.FC<{
  question: IQuestion;
  onResponseChanged?: (
    question: IQuestion,
    response: IQuestionResponse,
  ) => void;
  onChange?: (response: IQuestionResponse) => void;
  receiver?: UserMapItem;
  showVisibilityOptions?: boolean;
  disabled?: boolean;
  response: IQuestionResponse;
  autoFocus?: boolean;
  error?: string;
}> = ({
  question,
  onResponseChanged,
  onChange,
  receiver,
  showVisibilityOptions,
  disabled,
  response,
  autoFocus,
  error,
}) => {
  if (isOpenEndedQuestion(question)) {
    return (
      <InputTextQuestion
        key={question.token}
        question={question}
        response={response}
        onResponseChanged={(response) => {
          onResponseChanged?.(question, response);
          onChange?.(response);
        }}
        receiver={receiver}
        autoFocus={autoFocus}
        showVisibilityOptions={showVisibilityOptions}
        disabled={disabled}
        error={error}
      />
    );
  } else if (isRatingQuestion(question)) {
    return (
      <InputRatingQuestion
        receiver={receiver}
        key={question.token}
        question={question}
        response={response}
        onResponseChanged={(response) => {
          onResponseChanged?.(question, response);
          onChange?.(response);
        }}
        showVisibilityOptions={showVisibilityOptions}
        disabled={disabled}
        error={error}
      />
    );
  } else if (question.type === QuestionType.LABEL) {
    return (
      <Header3 style={{ whiteSpace: 'pre-wrap' }}>{question.text}</Header3>
    );
  }
  throw new Error('unhandled question type');
};
