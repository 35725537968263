import { useTeamPickerManagers } from '@client/TeamClient';
import { UserToken } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { UserPicker } from '@web/components/UserPicker';
import * as React from 'react';
import { useParams } from 'react-router-dom';

export const TeamPicker: React.FC<{
  onChange: (userToken: UserToken) => void;
}> = ({ onChange }) => {
  const { user: currentUser } = useAuth();
  const { userToken } = useParams<{ userToken?: UserToken }>();
  const { data: managers } = useTeamPickerManagers();
  if (!managers || managers.length === 0) {
    return null;
  }

  const pickerUsers = [currentUser, ...managers];
  return (
    <UserPicker
      selectedUserToken={userToken ?? currentUser.token}
      users={pickerUsers}
      onChange={onChange}
    />
  );
};
