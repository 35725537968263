import { IFeedbackTemplate } from '@shared/feedbackTemplates';
import { IQuestionResponse } from '@shared/question_response';
import { IFeedback } from '@shared/types';
import { IF_MOBILE } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { post, put } from '@web/common/api';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { Column } from '@web/components/layout';
import { Button, message } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { InputQuestions } from '../../questions/InputQuestions';
import { useQuestionResponseMap } from '../../questions/useQuestionResponseMap';

export const EditSelfFeedback: React.FC<{
  draft: IFeedback;
  template: IFeedbackTemplate;
  onSaveDraft: () => void;
  onShare: () => void;
}> = ({ onSaveDraft, onShare, draft, template }) => {
  const { user } = useAuth();
  const { responseMap, handleQuestionResponse } = useQuestionResponseMap(
    template.feedbackQuestions,
    draft.responses,
  );
  const [isSaving, setIsSaving] = React.useState(false);

  const handleSaveDraft = async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);
    const responses = Array.from(responseMap.values());
    try {
      if (responses.length > 0) {
        await put<{ responses: IQuestionResponse[] }>(`/feedback/self/draft`, {
          responses,
        });
        onSaveDraft();
        void message.success('Draft saved');
      }
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleShare = async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);
    const responses = Array.from(responseMap.values());
    try {
      if (responses.length > 0) {
        await post<{ responses: IQuestionResponse[] }>(`/feedback/self/share`, {
          responses,
        });
        onShare();
        void message.success('Feedback has been shared');
      }
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Column gap={24} style={{ maxWidth: 800 }}>
      <InputQuestions
        questions={template.feedbackQuestions}
        responseMap={responseMap}
        receiver={user}
        onResponseChanged={handleQuestionResponse}
      />
      <FormButtons>
        <ConfirmButton
          type="primary"
          onConfirm={() => {
            void handleShare();
          }}
          title="Share with manager"
          description={`Do you wish to share this feedback with your manager? This cannot be undone.`}
          okText="Continue"
        >
          Share with manager
        </ConfirmButton>
        <Button
          onClick={() => {
            void handleSaveDraft();
          }}
        >
          Save draft
        </Button>
      </FormButtons>
    </Column>
  );
};

const FormButtons = styled.div`
  max-width: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
  gap: 10px;

  ${IF_MOBILE} {
    max-width: 100vw;
  }
`;
