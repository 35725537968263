import { METRIC_COLORS } from '@shared/analytics';
import { Row, Spacer } from '@web/components/layout';
import { Header1, Text } from '@web/components/typography';
import { Progress } from 'antd';
import pluralize from 'pluralize';
import * as React from 'react';

export const CircleProgressMetric: React.FC<{
  value: number;
  total: number;
  color?: string;
  itemName?: string;
}> = ({ value, total, color = METRIC_COLORS[3], itemName }) => {
  return (
    <Row
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Row gap={6}>
        <Header1 style={{ fontSize: 24, color: '#333' }}>{value}</Header1>
        {itemName && (
          <Text style={{ fontSize: 16, fontWeight: 400 }}>
            {pluralize(itemName, value)}
          </Text>
        )}
      </Row>
      <Spacer size={12} />
      <Progress
        type="circle"
        percent={Math.round((value / total) * 100)}
        size={100}
        strokeLinecap="square"
        strokeColor={color}
      />
    </Row>
  );
};

export const ProgressMetric: React.FC<{
  value: number;
  total: number;
  color?: string;
}> = ({ value, total, color = METRIC_COLORS[3] }) => {
  return (
    <Row
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Progress
        percent={Math.round((value / total) * 100)}
        size={[150, 40]}
        strokeLinecap="square"
        strokeColor={color}
        format={(percent) => (
          <Header1 style={{ fontSize: 18 }}>{percent}%</Header1>
        )}
        style={{ whiteSpace: 'nowrap' }}
      />
    </Row>
  );
};
