import {
  GiveUpwardFeedbackRequest,
  UserFeedbackResponse,
} from '@shared/feedback';
import {
  FeedbackTemplateToken,
  IFeedbackTemplate,
  upwardFeedbackTemplates,
} from '@shared/feedbackTemplates';
import { IQuestionResponse } from '@shared/question_response';
import { IQuestion, QuestionToken } from '@shared/questions';
import {
  FeedbackVisibility,
  IFeedbackRequest,
  IUser,
  TaskResult,
} from '@shared/types';
import { primaryManager } from '@shared/users';
import { PageContent } from '@web/app/Page';
import { IF_MOBILE, useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { ServerResponseError, put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { useNavigateBack } from '@web/common/useNavigateBack';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { SelectVisibility } from '@web/components/SelectVisibility';
import { UserMessage } from '@web/components/UserMessage';
import { Column, Spacer } from '@web/components/layout';
import { Header3 } from '@web/components/typography';
import { Button, Select, Skeleton, message } from 'antd';
import * as React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useMap } from 'usehooks-ts';

import { InputQuestions } from '../questions/InputQuestions';

export const GiveUpwardFeedbackPage: React.FC = () => {
  const navigateBack = useNavigateBack();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isMobile } = useResponsive();
  const { managers } = useAuth();
  const isTask = searchParams.has('t');
  const { data: taskResult, error } = useApi<TaskResult, ServerResponseError>(
    isTask ? `/tasks/${searchParams.get('t')}` : null,
  );

  if (!taskResult && searchParams.has('t') && !error) {
    return (
      <PageContent>
        <PageHeader
          title="Upward feedback"
          mobileTitle="Upward feedback"
          navigateBack
          defaultNavigateBackTo="/feedback"
        />
        <Skeleton />
      </PageContent>
    );
  }
  const feedbackRequest = taskResult?.task?.feedbackRequests?.[0];
  if (error || (isTask && !feedbackRequest)) {
    return (
      <ErrorPageContent
        statusCode={error?.statusCode}
        title={
          error?.statusCode === 404
            ? 'This page does not exist'
            : 'Give feedback'
        }
        extra={
          <Link to="/feedback">
            <Button>Back to Feedback</Button>
          </Link>
        }
      />
    );
  }
  const handleCancel = () => {
    navigateBack();
  };
  const handleSave = () => {
    navigate('/feedback/given');
  };

  const manager = managers ? primaryManager(managers) : undefined;
  return (
    <PageContent>
      <PageHeader title="Upward Feedback" navigateBack />
      <Spacer />
      <Pane>
        {manager ? (
          <GiveUpwardFeedbackForm
            receiver={manager}
            onCancel={handleCancel}
            onSave={handleSave}
            feedbackRequest={feedbackRequest}
          />
        ) : (
          <Skeleton />
        )}
      </Pane>

      {isMobile && <Spacer />}
    </PageContent>
  );
};

type ResponseMap = Map<QuestionToken, IQuestionResponse>;

export const GiveUpwardFeedbackForm: React.FC<{
  receiver: IUser;
  templateToken?: FeedbackTemplateToken;
  feedbackRequest?: IFeedbackRequest;
  isViewing?: boolean;
  disabled?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
}> = ({
  receiver,
  feedbackRequest,
  templateToken,
  isViewing,
  disabled,
  onSave,
  onCancel,
}) => {
  const initialTemplate: IFeedbackTemplate =
    feedbackRequest || templateToken
      ? upwardFeedbackTemplates.find(
          (t) =>
            t.token === (feedbackRequest?.questionSetToken ?? templateToken),
        ) ?? upwardFeedbackTemplates[0]
      : upwardFeedbackTemplates[0];
  const [template, setTemplate] =
    React.useState<IFeedbackTemplate>(initialTemplate);
  const [visibility, setVisibility] = React.useState<FeedbackVisibility>(
    FeedbackVisibility.RECEIVER_MANAGER,
  );
  const [responseMap, responseMapActions] = useMap(
    createInitialResponseMap(template.feedbackQuestions, []),
  );
  const responseMapsRef = React.useRef(
    new Map<
      FeedbackTemplateToken,
      Omit<Map<QuestionToken, IQuestionResponse>, 'set' | 'clear' | 'delete'>
    >(),
  );
  const [isSaving, setIsSaving] = React.useState(false);
  React.useEffect(() => {
    if (templateToken) {
      handleTemplateChange(templateToken);
    }
  }, [templateToken]);

  const handleQuestionResponse = (
    question: IQuestion,
    response: IQuestionResponse,
  ) => {
    const currentResponse = responseMap.get(question.token);
    responseMapActions.set(question.token, {
      ...currentResponse,
      ...response,
    });
  };

  const handleTemplateChange = (value: FeedbackTemplateToken) => {
    if (!template) {
      return;
    }

    responseMapsRef.current.set(template.token, responseMap);
    const pastResponseMap = responseMapsRef.current.get(value);
    const nextTemplate = upwardFeedbackTemplates.find(
      (template) => template.token === value,
    );
    if (nextTemplate) {
      setTemplate(nextTemplate);

      responseMapActions.setAll(
        pastResponseMap
          ? [...pastResponseMap.entries()]
          : createInitialResponseMap(nextTemplate.feedbackQuestions, []),
      );
    }
  };

  const handleSave = async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);
    const responses: UserFeedbackResponse[] = Array.from(
      responseMap.values(),
    ).map((qr) => ({
      questionToken: qr.questionToken,
      visibility: feedbackRequest ? feedbackRequest.visibility : visibility,
      text: qr.text,
      rating: qr.rating,
    })) as UserFeedbackResponse[];
    try {
      if (responses.length > 0) {
        await put<GiveUpwardFeedbackRequest>(`/feedback/upward`, {
          responses,
          templateToken: template.token,
          feedbackRequestToken: feedbackRequest?.token,
        });
        onSave?.();
        void message.success('Success');
      }
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  if (!template) {
    return null;
  }

  const templateOptions = upwardFeedbackTemplates.map((template) => ({
    value: template.token,
    label: template.name,
  }));
  return (
    <UserMessage user={receiver} large>
      <Spacer size={12} />
      <Column gap={24} style={{ maxWidth: 800 }}>
        {!feedbackRequest && (
          <Select
            value={template.token}
            onChange={handleTemplateChange}
            options={templateOptions}
            disabled={disabled}
          />
        )}
        <InputQuestions
          questions={template.feedbackQuestions}
          responseMap={responseMap}
          receiver={receiver}
          onResponseChanged={handleQuestionResponse}
          showVisibilityOptions={false}
          disabled={disabled}
        />
        {!feedbackRequest && (
          <Column>
            <Header3>Visibility</Header3>
            <Spacer size={10} />
            <SelectVisibility
              size="normal"
              userName={receiver.name}
              value={visibility}
              onChange={(visibility) => {
                setVisibility(visibility);
              }}
            />
          </Column>
        )}
        {!isViewing ? (
          <FormButtons>
            <Button
              type="primary"
              onClick={() => {
                void handleSave();
              }}
            >
              Give feedback
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </FormButtons>
        ) : undefined}
      </Column>
    </UserMessage>
  );
};

const FormButtons = styled.div`
  max-width: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
  gap: 10px;

  ${IF_MOBILE} {
    max-width: 100vw;
  }
`;

const createInitialResponseMap = (
  questions?: IQuestion[],
  responses?: IQuestionResponse[],
): ResponseMap => {
  const responseMap = new Map<QuestionToken, IQuestionResponse>();
  if (!questions) {
    return responseMap;
  }

  for (const question of questions) {
    const response = responses?.find(
      (response) => response.questionToken === question.token,
    ) ?? {
      visibility: FeedbackVisibility.RECEIVER_MANAGER,
      questionToken: question.token,
    };
    responseMap.set(question.token, response as any);
  }
  return responseMap;
};
