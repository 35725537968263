import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { useIndividualAnalytics } from '@client/AnalyticsClient';
import {
  IUserMetrics,
  METRIC_GREEN,
  METRIC_RED,
  METRIC_YELLOW,
  MetricType,
  MetricTypeDescriptions,
  MetricTypeLabels,
  UserMetrics,
} from '@shared/analytics';
import { ITeam, IUser, UserToken } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { AlignmentStateIndicator } from '@web/1on1s/AlignmentStateIndicator';
import { ManagerOneOnOneTypeLabels } from '@web/1on1s/ManagerOneOnOneTypeLabels';
import { errorColor, successColor } from '@web/app/styles/ColorStyles';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, NoWrap, Row } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import { Skeleton, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { filter } from 'lodash';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { BarGraphMetric } from './visualizations/BarGraphMetric';
import { LineGraphMetric } from './visualizations/LineGraphMetric';

export const UserMetricsTable: React.FC<{
  teams: ITeam[];
  users: IUser[];
  metricTypes: MetricType[];
}> = ({ teams, users, metricTypes }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const managerTokenFilter: UserToken = searchParams.get('manager') as any;
  const filteredUsers = managerTokenFilter
    ? users.filter((user) => isManagerOf(user, managerTokenFilter))
    : users;
  const managerTeam = managerTokenFilter
    ? teams.find((team) => team.managerToken === managerTokenFilter)
    : null;
  const { data: userMetrics } = useIndividualAnalytics(
    filteredUsers.map((u) => u.token),
  );

  if (!userMetrics) {
    return <Skeleton />;
  }

  const columns: ColumnsType<IUser> = [
    {
      title: 'Person',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render(name, user) {
        return (
          <Row gap={6}>
            <UserAvatar user={user} size={28} /> <NoWrap>{name}</NoWrap>
          </Row>
        );
      },
    },
  ];
  for (const metricType of metricTypes) {
    columns.push(createMetricColumn(metricType, userMetrics));
  }
  columns.push({
    title: '',
    width: '100%',
    render: () => '',
  });

  return (
    <Column gap={12}>
      {managerTeam?.manager && (
        <FilterTag>
          Showing reports for {managerTeam.manager?.name}{' '}
          <CloseOutlined
            onClick={() => {
              setSearchParams((prev) => {
                prev.delete('manager');
                return prev;
              });
            }}
          />
        </FilterTag>
      )}
      <Table
        dataSource={filteredUsers}
        columns={filter(columns)}
        scroll={{ x: true }}
      />
    </Column>
  );
};

const MetricLabel = styled(Text)`
  font-weight: 500;
`;

const CustomHeaderLabels: Partial<Record<MetricType, React.ReactNode>> = {
  [MetricType.ONE_ON_ONES_SCHEDULED]: (
    <MetricLabel>
      Scheduled <NoWrap>1-on-1s</NoWrap>
    </MetricLabel>
  ),
  [MetricType.ONE_ON_ONES_USERS_WITH_COMPLETED]: (
    <MetricLabel>
      Completed <NoWrap>1-on-1s</NoWrap>
    </MetricLabel>
  ),
  [MetricType.ONE_ON_ONES_ALIGNMENT]: (
    <MetricLabel>
      <NoWrap>1-on-1</NoWrap> Alignment
    </MetricLabel>
  ),
  [MetricType.MANAGER_ONE_ON_ONES_SCHEDULED]: (
    <MetricLabel>
      Scheduled Manager <NoWrap>1-on-1s</NoWrap>
    </MetricLabel>
  ),
};

const createMetricColumn = (
  metricType: MetricType,
  userMetrics: UserMetrics,
): ColumnsType<IUser>[number] => {
  const label = CustomHeaderLabels[metricType] ?? (
    <MetricLabel>{MetricTypeLabels[metricType]}</MetricLabel>
  );

  return {
    title: (
      <Tooltip title={MetricTypeDescriptions[metricType]}>{label}</Tooltip>
    ),
    key: metricType,
    align: 'center',
    render(_, user: IUser) {
      return (
        <ViewTableMetric metrics={userMetrics[user.token]} type={metricType} />
      );
    },
  };
};

export const ViewTableMetric: React.FC<{
  type: MetricType;
  metrics: IUserMetrics;
}> = ({ type, metrics }) => {
  switch (type) {
    case MetricType.GOALS:
      return (
        <BarGraphMetric
          labeledMetrics={[
            {
              label: 'Off Track',
              value: metrics.goalsOffTrack,
              color: METRIC_RED,
            },
            {
              label: 'At Risk',
              value: metrics.goalsAtRisk,
              color: METRIC_YELLOW,
            },
            {
              label: 'On Track',
              value: metrics.goalsOnTrack,
              color: METRIC_GREEN,
            },
          ]}
          horizontal
          width={120}
          height={50}
        />
      );
    case MetricType.JOURNAL_WEEKLY_ENTRIES:
      return (
        <LineGraphMetric
          labeledMetrics={metrics.journalWeeklyUsage}
          width={150}
          height={70}
          hideLabels
        />
      );
    case MetricType.JOURNAL_USER_WITH_ENTRIES:
      return metrics.journalActivity ? (
        <CheckCircleOutlined style={{ color: successColor.string() }} />
      ) : (
        <CloseCircleOutlined style={{ color: errorColor.string() }} />
      );
    case MetricType.ONE_ON_ONES_ALIGNMENT:
      return <AlignmentStateIndicator state={metrics.oneOnOnesAlignment} />;
    case MetricType.ONE_ON_ONES_SCHEDULED:
      return <Header3>{metrics.oneOneOnesScheduled}</Header3>;
    case MetricType.ONE_ON_ONES_USERS_WITH_COMPLETED:
      return <Header3>{metrics.oneOneOnesCompleted}</Header3>;
    case MetricType.JOURNAL_ALIGNMENT:
      return <AlignmentStateIndicator state={metrics.journalAlignment} />;
    case MetricType.MANAGER_ONE_ON_ONES_SCHEDULED:
      return (
        <Text>
          {metrics.schedule
            ? ManagerOneOnOneTypeLabels[metrics.schedule]
            : 'Unscheduled'}
        </Text>
      );
    default:
      return null;
  }
};

const FilterTag = styled(Text)`
  background: #eee;
  border: 1px solid #bbb;
  border-radius: 12px;
  line-height: 24px;
  padding: 0 12px;
  align-self: flex-start;
`;
