import { PlusOutlined } from '@ant-design/icons';
import { cloneQuestion, deleteQuestion } from '@client/SurveyCyclesClient';
import { IQuestion, QuestionToken } from '@shared/questions';
import { ISurveyCycle, SurveyCycleToken } from '@shared/surveys';
import { AdminPageContent } from '@web/admin/AdminPageContent';
import { useApi } from '@web/common/useApi';
import { GrowingSpacer, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Skeleton, message } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { EditQuestionModal } from '../questions/EditQuestionModal';
import { QuestionsList } from '../questions/QuestionsList';
import { AdminSurveyContent } from './AdminSurveyContent';

export const AdminSurveyCycleQuestionsPage: React.FC = () => {
  const { surveyCycleToken } = useParams<{
    surveyCycleToken?: SurveyCycleToken;
  }>();
  const { data: surveyCycle, mutate: reloadSurvey } = useApi<ISurveyCycle>(
    `/survey-cycles/${surveyCycleToken}`,
  );
  const [showAddQuestion, setShowAddQuestion] = React.useState(false);
  const [editingQuestion, setEditingQuestion] = React.useState<
    IQuestion | undefined
  >(undefined);

  const handleQuestionUpdated = () => {
    setShowAddQuestion(false);
    setEditingQuestion(undefined);
    void reloadSurvey();
  };

  const handleQuestionCancel = () => {
    setShowAddQuestion(false);
    setEditingQuestion(undefined);
  };

  const handleEditQuestion = (question: IQuestion) => {
    setEditingQuestion(question);
  };

  const handleDeleteQuestion = async (questionToken: QuestionToken) => {
    if (!surveyCycle) {
      return;
    }

    try {
      await deleteQuestion(surveyCycle.token, questionToken);
      void reloadSurvey();
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleCloneQuestion = async (questionToken: QuestionToken) => {
    if (!surveyCycle) {
      return;
    }

    try {
      await cloneQuestion(surveyCycle.token, questionToken);
      void message.success('Success');
      void reloadSurvey();
    } catch (error) {
      void message.error('Error');
    }
  };

  if (!surveyCycle) {
    return (
      <AdminPageContent>
        <Skeleton />
      </AdminPageContent>
    );
  }

  return (
    <AdminSurveyContent surveyCycle={surveyCycle} onChange={reloadSurvey}>
      {!surveyCycle.startedDate && (
        <Row style={{ marginBottom: 12 }}>
          {surveyCycle.questions?.length > 0 ? (
            <Text>Survey questions:</Text>
          ) : (
            <Text>No questions have been added</Text>
          )}
          <GrowingSpacer />
          <Button
            onClick={() => {
              setShowAddQuestion(true);
            }}
          >
            <PlusOutlined /> Add Question
          </Button>
        </Row>
      )}
      <QuestionsList
        questions={surveyCycle.questions}
        onEdit={handleEditQuestion}
        onDelete={handleDeleteQuestion}
        onClone={handleCloneQuestion}
        readonly={!!surveyCycle.startedDate}
      />
      {(showAddQuestion || !!editingQuestion) && (
        <EditQuestionModal
          surveyCycle={surveyCycle}
          onUpdate={handleQuestionUpdated}
          onCancel={handleQuestionCancel}
          open={true}
          question={editingQuestion}
        />
      )}
    </AdminSurveyContent>
  );
};
