import { ITeamMetrics, TeamMetrics, UserMetrics } from '@shared/analytics';
import { TeamToken, UserToken } from '@shared/types';
import { get } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function useOverviewAnalytics(teamTokens: TeamToken[]) {
  const url = stringifyUrl({
    url: `/analytics/overview`,
    query: {
      teams: teamTokens,
    },
  });

  return useApi<ITeamMetrics>(url);
}

export function overviewAnalytics(teamTokens: TeamToken[]) {
  const url = stringifyUrl({
    url: `/analytics/overview`,
    query: {
      teams: teamTokens,
    },
  });

  return get<ITeamMetrics>(url);
}

export function useTeamAnalytics(teamTokens: TeamToken[]) {
  const url = stringifyUrl({
    url: `/analytics/team`,
    query: {
      teams: teamTokens,
    },
  });

  return useApi<TeamMetrics>(url);
}

export function teamAnalytics(teamTokens: TeamToken[]) {
  const url = stringifyUrl({
    url: `/analytics/team`,
    query: {
      teams: teamTokens,
    },
  });

  return get<TeamMetrics>(url);
}

export function useIndividualAnalytics(userTokens: UserToken[]) {
  const url = stringifyUrl({
    url: `/analytics/individual`,
    query: {
      users: userTokens,
    },
  });

  return useApi<UserMetrics>(url);
}

export function individualAnalytics(userTokens: UserToken[]) {
  const url = stringifyUrl({
    url: `/analytics/individual`,
    query: {
      users: userTokens,
    },
  });

  return get<UserMetrics>(url);
}
