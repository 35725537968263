import { ILabeledMetric } from '@shared/analytics';
import { Column } from '@web/components/layout';
import ReactECharts from 'echarts-for-react';
import React from 'react';

export const BarGraphMetric: React.FC<{
  labeledMetrics: ILabeledMetric[];
  horizontal?: boolean;
  hideLabels?: boolean;
  width?: number;
  height?: number;
}> = ({
  labeledMetrics,
  horizontal = false,
  hideLabels = false,
  width = 250,
  height = 100,
}) => {
  const xAxisData: string[] = [];
  const seriesData: Array<number | null> = [];
  for (const labeledMetric of labeledMetrics) {
    xAxisData.push(labeledMetric.label);
    seriesData.push(
      labeledMetric.color
        ? ({
            value: labeledMetric.value,
            itemStyle: { color: labeledMetric.color },
          } as any)
        : labeledMetric.value,
    );
  }

  const chartOptions = createBarChartOptions(
    xAxisData,
    seriesData,
    horizontal,
    hideLabels,
  );
  return (
    <Column style={{ alignItems: 'center' }}>
      <ReactECharts
        style={{ width, height, padding: 0 }}
        option={chartOptions}
      />
    </Column>
  );
};

const createBarChartOptions = (
  xAxisData: string[],
  seriesData: Array<number | null>,
  horizontal = false,
  hideLabels = false,
) => {
  const valueAxis = {
    type: 'value',
    show: false,
  };
  const categoryAxis = {
    type: 'category',
    data: xAxisData,
    show: !horizontal && !hideLabels,
  };

  return {
    xAxis: horizontal ? valueAxis : categoryAxis,
    yAxis: !horizontal ? valueAxis : categoryAxis,
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        data: seriesData,
        type: 'bar',
        label: {
          show: true,
          position: horizontal ? 'right' : 'top',
          color: '#333',
          fontSize: 12,
        },
        smooth: true,
      },
    ],
    grid: {
      left: '0',
      right: horizontal ? '20px' : '0',
      top: horizontal ? '0' : '20px',
      bottom: horizontal ? '0' : '20px',
    },
  };
};
