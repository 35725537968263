import { useOverviewAnalytics } from '@client/AnalyticsClient';
import {
  ITeamMetrics,
  METRIC_COLORS,
  METRIC_GREEN,
  METRIC_RED,
  METRIC_YELLOW,
  MetricType,
  MetricTypeDescriptions,
  MetricTypeLabels,
} from '@shared/analytics';
import { ITeam } from '@shared/types';
import { IF_MOBILE } from '@web/app/responsive';
import { Column, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Skeleton, Tooltip } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

import { BarGraphMetric } from './visualizations/BarGraphMetric';
import { LineGraphMetric } from './visualizations/LineGraphMetric';
import { PieChartMetric } from './visualizations/PieChartMetric';
import { CircleProgressMetric } from './visualizations/ProgressMetric';

export const MetricsOverview: React.FC<{
  metricTypes: MetricType[];
  teams: ITeam[];
}> = ({ metricTypes: visibleMetrics, teams: visibleTeams }) => {
  const { data: overviewMetrics } = useOverviewAnalytics(
    visibleTeams.map((t) => t.token),
  );
  // const teamMetrics = generateTeamMetrics(visibleTeams);
  // const overviewMetrics = computeAggregateMetrics(teamMetrics);

  if (!overviewMetrics) {
    return <Skeleton />;
  }
  return (
    <MetricsContainer>
      {visibleMetrics.map((metricType) => (
        <MetricContainer key={metricType}>
          {MetricTypeDescriptions[metricType] ? (
            <Tooltip title={MetricTypeDescriptions[metricType]}>
              <MetricText>{MetricTypeLabels[metricType]}</MetricText>
            </Tooltip>
          ) : (
            <MetricText>{MetricTypeLabels[metricType]}</MetricText>
          )}
          <Spacer size={18} />
          <VisualizationContainer>
            <ViewMetric type={metricType} metrics={overviewMetrics} />
          </VisualizationContainer>
          <Spacer size={18} />
        </MetricContainer>
      ))}
    </MetricsContainer>
  );
};

const MetricText = styled(Text)`
  line-height: 14px;
`;

const MetricsContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  max-width: 968px;

  ${IF_MOBILE} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const MetricContainer = styled(Column)`
  border: 1px solid var(--border-color);
  border-radius: var(--default-border-radius);
  padding: 12px 14px;
`;

const VisualizationContainer = styled(Column)`
  align-items: center;
  justify-content: center;
`;

const ViewMetric: React.FC<{ type: MetricType; metrics: ITeamMetrics }> = ({
  type,
  metrics,
}) => {
  switch (type) {
    case MetricType.GOALS:
      return (
        <BarGraphMetric
          labeledMetrics={[
            {
              label: 'Off Track',
              value: metrics.goalsOffTrack,
              color: METRIC_RED,
            },
            {
              label: 'At Risk',
              value: metrics.goalsAtRisk,
              color: METRIC_YELLOW,
            },
            {
              label: 'On Track',
              value: metrics.goalsOnTrack,
              color: METRIC_GREEN,
            },
          ]}
          horizontal
          height={100}
        />
      );
    case MetricType.ONE_ON_ONES_SCHEDULED:
      return (
        <CircleProgressMetric
          value={metrics.oneOnOnesScheduled}
          total={metrics.userCount}
          color={METRIC_COLORS[3]}
        />
      );
    case MetricType.ONE_ON_ONES_USERS_WITH_COMPLETED:
      return (
        <CircleProgressMetric
          value={metrics.oneOnOnesUsersWithCompleted}
          total={metrics.userCount}
          color={METRIC_COLORS[4]}
          itemName="user"
        />
      );
    case MetricType.JOURNAL_USER_WITH_ENTRIES:
      return (
        <CircleProgressMetric
          value={metrics.journalUsersWithEntries}
          total={metrics.userCount}
          color={METRIC_COLORS[2]}
          itemName="user"
        />
      );
    case MetricType.JOURNAL_WEEKLY_ENTRIES:
      return <LineGraphMetric labeledMetrics={metrics.journalWeeklyUsage} />;
    case MetricType.ONE_ON_ONES_ALIGNMENT:
      return (
        <PieChartMetric
          data={[
            {
              name: 'On Track',
              value: metrics.oneOnOnesAlignmentOnTrack,
              color: METRIC_GREEN,
            },
            {
              name: 'At Risk',
              value: metrics.oneOnOnesAlignmentAtRisk,
              color: METRIC_YELLOW,
            },
            {
              name: 'Off Track',
              value: metrics.oneOnOnesAlignmentOffTrack,
              color: METRIC_RED,
            },
          ]}
        />
      );

    case MetricType.JOURNAL_ALIGNMENT:
      return (
        <PieChartMetric
          data={[
            {
              name: 'On Track',
              value: metrics.journalAlignmentOnTrack,
              color: METRIC_GREEN,
            },
            {
              name: 'At Risk',
              value: metrics.journalAlignmentAtRisk,
              color: METRIC_YELLOW,
            },
            {
              name: 'Off Track',
              value: metrics.journalAlignmentOffTrack,
              color: METRIC_RED,
            },
          ]}
        />
      );
    case MetricType.MANAGER_ONE_ON_ONES_SCHEDULED:
      return (
        <CircleProgressMetric
          value={metrics.managerOneOnOnesScheduled}
          total={metrics.userCount}
          color={METRIC_COLORS[2]}
          itemName="user"
        />
      );
    default:
      return null;
  }
};
