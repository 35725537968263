import { useAlignmentIndicators } from '@client/AlignmentClient';
import {
  ALIGNMENT_INDICATOR_NAMES,
  IAlignmentIndicator,
} from '@shared/alignment';
import { Feature } from '@shared/features';
import { UserToken } from '@shared/types';
import { AlignmentStatus } from '@web/alignment/AlignmentStatus';
import { useAuth } from '@web/auth/useAuth';
import { useFeature } from '@web/common/useFeature';
import { Column, Row, Spacer, WrapRow } from '@web/components/layout';
import { Header2, Text } from '@web/components/typography';
import { Modal, Skeleton } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { AlignmentStateIndicator } from './AlignmentStateIndicator';

export const OneOnOneAlignment: React.FC<{ userToken: UserToken }> = ({
  userToken,
}) => {
  const { user } = useAuth();
  const { booleanValue: alignmentIndicatorsEnabled } = useFeature(
    Feature.ENABLE_ALIGNMENT_INDICATORS,
  );
  const { data: indicators } = useAlignmentIndicators(userToken);
  if (!alignmentIndicatorsEnabled) {
    return null;
  }

  if (!indicators) {
    return <Skeleton />;
  }

  if (indicators.length === 0) {
    return null;
  }

  const isSelf = user.token === userToken;

  return (
    <Column>
      <Link to={isSelf ? '/alignment' : `/alignment/${userToken}`}>
        <Header2>Alignment Indicators</Header2>
      </Link>
      <Spacer size={6} />
      {indicators.length > 0 ? (
        <Column gap={12}>
          {indicators.map((indicator) => (
            <AlignmentIndicatorRow
              key={indicator.token}
              indicator={indicator}
            />
          ))}
        </Column>
      ) : null}
    </Column>
  );
};

const AlignmentIndicatorRow: React.FC<{ indicator: IAlignmentIndicator }> = ({
  indicator,
}) => {
  const [open, setOpen] = React.useState(false);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Column gap={6}>
      <Row style={{ cursor: 'auto' }}>
        <WrapRow
          gap={12}
          style={{ rowGap: 0, cursor: 'pointer' }}
          onClick={openModal}
        >
          <Text style={{ width: 127 }}>
            {ALIGNMENT_INDICATOR_NAMES[indicator.type]}
          </Text>
          <AlignmentStateIndicator state={indicator.state} />
        </WrapRow>
      </Row>
      <Modal
        title="Alignment"
        open={open}
        onCancel={closeModal}
        footer={() => null}
      >
        <AlignmentStatus indicator={indicator} />
      </Modal>
    </Column>
  );
};
