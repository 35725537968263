import { AlignmentState } from '@shared/alignment';
import { TeamToken, UserToken } from '@shared/types';

import { ScheduleType } from './one-on-one';

export interface ITeamGoalMetrics {
  goalsOnTrack: number;
  goalsAtRisk: number;
  goalsOffTrack: number;
}
export interface ITeamJournalAlignmentMetrics {
  journalAlignmentOnTrack: number;
  journalAlignmentAtRisk: number;
  journalAlignmentOffTrack: number;
}
export interface ITeamOneOnOneAlignmentMetrics {
  oneOnOnesAlignmentOnTrack: number;
  oneOnOnesAlignmentAtRisk: number;
  oneOnOnesAlignmentOffTrack: number;
}
export interface ITeamOneOnOneMetrics {
  oneOnOnesScheduled: number;
  oneOnOnesUsersWithCompleted: number;
}

export interface ITeamManagerOneOnOneMetrics {
  managerOneOnOnesScheduled: number;
}

export interface ILabeledMetric {
  label: string;
  value: number;
  color?: string;
}
export interface ITeamJournalMetrics {
  journalWeeklyUsage: ILabeledMetric[];
  journalUsersWithEntries: number;
}
export interface ITeamMetrics
  extends ITeamGoalMetrics,
    ITeamOneOnOneMetrics,
    ITeamJournalMetrics,
    ITeamJournalAlignmentMetrics,
    ITeamOneOnOneAlignmentMetrics,
    ITeamManagerOneOnOneMetrics {
  userCount: number;
}

export interface IUserGoalMetrics {
  goalsOnTrack: number;
  goalsAtRisk: number;
  goalsOffTrack: number;
}

export interface IUserJournalAlignmentMetrics {
  journalAlignment: AlignmentState;
}

export interface IUserOneOnOneMetrics {
  oneOneOnesScheduled: number;
  oneOneOnesCompleted: number;
}

export interface IUserOneOnOneAlignmentMetrics {
  oneOnOnesAlignment: AlignmentState;
}

export interface IUserJournalMetrics {
  journalWeeklyUsage: ILabeledMetric[];
  journalActivity: boolean;
}

export interface IUserManagerOneOnOneMetrics {
  managerOneOnOnesCompleted: number;
  schedule: ScheduleType | undefined;
}

export interface IUserMetrics
  extends IUserGoalMetrics,
    IUserOneOnOneAlignmentMetrics,
    IUserJournalAlignmentMetrics,
    IUserOneOnOneMetrics,
    IUserJournalMetrics,
    IUserManagerOneOnOneMetrics {}

export type UserMetrics = Record<UserToken, IUserMetrics>;
export type TeamMetrics = Record<TeamToken, ITeamMetrics>;
export enum MetricType {
  GOALS = 'GOALS',
  JOURNAL_ALIGNMENT = 'JOURNAL_ALIGNMENT',
  ONE_ON_ONES_SCHEDULED = 'ONE_ON_ONES_SCHEDULED',
  ONE_ON_ONES_USERS_WITH_COMPLETED = 'ONE_ON_ONES_USERS_WITH_COMPLETED',
  ONE_ON_ONES_ALIGNMENT = 'ONE_ON_ONES_ALIGNMENT',
  JOURNAL_WEEKLY_ENTRIES = 'JOURNAL_ENTRIES_PER_WEEK',
  JOURNAL_USER_WITH_ENTRIES = 'JOURNAL_USER_WITH_ENTRIES',
  MANAGER_ONE_ON_ONES_SCHEDULED = 'MANAGER_ONE_ON_ONES',
}

export const MetricTypeLabels: Record<MetricType, string> = {
  [MetricType.GOALS]: 'Goals',
  [MetricType.ONE_ON_ONES_SCHEDULED]: 'Scheduled 1-on-1s',
  [MetricType.ONE_ON_ONES_USERS_WITH_COMPLETED]: 'Users Completing 1-on-1s',
  [MetricType.JOURNAL_WEEKLY_ENTRIES]: 'Weekly Journal Entries',
  [MetricType.JOURNAL_USER_WITH_ENTRIES]: 'Journal Activity',
  [MetricType.ONE_ON_ONES_ALIGNMENT]: '1-on-1s Alignment',
  [MetricType.JOURNAL_ALIGNMENT]: 'Journal Alignment',
  [MetricType.MANAGER_ONE_ON_ONES_SCHEDULED]: 'Scheduled Manager 1-on-1s',
};

export const MetricTypeDescriptions: Record<MetricType, string> = {
  [MetricType.GOALS]: 'Breakdown of active goal state as of today',
  [MetricType.ONE_ON_ONES_SCHEDULED]: 'None yet',
  [MetricType.ONE_ON_ONES_USERS_WITH_COMPLETED]: 'None yet',
  [MetricType.JOURNAL_WEEKLY_ENTRIES]: 'None yet',
  [MetricType.JOURNAL_USER_WITH_ENTRIES]: 'None yet',
  [MetricType.ONE_ON_ONES_ALIGNMENT]: 'None yet',
  [MetricType.JOURNAL_ALIGNMENT]: 'None yet',
  [MetricType.MANAGER_ONE_ON_ONES_SCHEDULED]: 'None yet',
};
// palette created from here: https://www.learnui.design/tools/data-color-picker.html

export const METRIC_COLORS = [
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
];
export const METRIC_RED = '#f35b4d';
export const METRIC_YELLOW = METRIC_COLORS[7];
export const METRIC_GREEN = '#1fbd00';
