import { TypographyColors } from '@web/components/typography';
import { css } from 'styled-components';

export const AntStyles = css`
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    top: -1.5px;
  }

  .ant-steps-item-icon .ant-steps-icon {
    top: -2.5px;
  }

  // remove spacing below tabs so it can rest on top of Pane
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }

  .ant-tag,
  .ant-tag a,
  .ant-tag a:hover {
    border-radius: 15px;
    font-size: 10px;
  }

  // fonts
  body,
  .ant-table,
  .ant-btn,
  .ant-btn.ant-btn-lg,
  .ant-form-item-label > label,
  .ant-input,
  .ant-select,
  .ant-select-item,
  .ant-form-item-explain,
  .ant-form-item-extra {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
  }

  .ant-form-item .ant-form-item-label > label {
    color: ${TypographyColors.text};
  }

  .ant-btn,
  .ant-btn.ant-btn-lg {
    border-radius: var(--default-border-radius);
  }

  .ant-select-item-option {
    .ant-select-item-option-content {
      white-space: pre-wrap !important;
    }
  }

  .ant-table {
    border: 1px solid var(--border-color);
    border-radius: var(--default-border-radius);
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    color: ${TypographyColors.text};
  }

  .ant-btn {
    svg {
      font-size: 11px;
    }
  }

  .ant-input[disabled] {
    background: white;
    color: #aaa;
  }

  .ant-btn,
  .ant-input,
  .ant-input-affix-wrapper,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: var(--default-border-radius);
  }

  .ant-btn.button-black {
    color: white;
    background: #333;
    border: 1px solid #333;

    &:hover {
      background: #555;
      border: 1px solid #555;
    }
  }

  .ant-btn-ghost:hover,
  .ant-btn-ghost:focus {
    background: none;
    border: 1px solid #d9d9d9;
    color: #000000d9;
  }

  .ant-btn-default:hover,
  .ant-btn-default:focus {
    background: white;
    border: 1px solid #d9d9d9;
    color: #000000d9;
  }

  textarea.ant-input {
    border-radius: var(--default-border-radius);
  }

  .ant-input-group-addon:last-child {
    border-top-right-radius: var(--default-border-radius);
    border-bottom-right-radius: var(--default-border-radius);
  }

  .ant-select:hover,
  .ant-select:focus,
  .ant-input:hover,
  .ant-input:focus {
    border-color: #ff7729;
    box-shadow: none;
  }

  .ant-form-item-label
    // eslint-disable-next-line prettier/prettier
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    ):before {
    color: var(--ant-primary-color);
  }

  .ant-popover-message > .anticon {
    color: var(--ant-primary-color);
  }

  .ant-card {
    border-radius: var(--default-border-radius);
    overflow: hidden;

    .ant-card-head {
      background: #f9f3f0;
      min-height: 0;

      .ant-card-head-title,
      .ant-card-extra {
        font-size: 14px;
        padding: 8px 0;
      }
    }
  }

  .ant-ribbon {
    top: 30px;
    line-height: 30px;
    height: 30px;
  }

  .ant-list-item {
    padding: 20px 0;
  }

  .ant-divider-horizontal {
    margin: 20px 0;
  }

  .ant-tooltip-inner {
    background-color: #333;
    padding: 15px 20px;
    border-radius: var(--default-border-radius);
  }

  .ant-card-meta-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .ant-card-meta-title {
      font-size: 18px;
    }

    .ant-card-meta-description {
      font-size: 14px;
    }

    & > div:not(:last-child) {
      margin: 0;
    }
  }

  .ant-result-info .ant-result-icon > .anticon {
    color: var(--ant-primary-color);
  }

  .confirm-button-popup {
    max-width: 350px;
    min-width: 200px;

    .ant-popover-message-title {
      padding-left: 0;
    }

    .ant-popover-buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;

      .ant-btn {
        height: 30px;
        margin: 0;
      }
    }
  }

  .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    border-color: var(--primary-color);
  }

  h1.ant-typography,
  h2.ant-typography,
  h3.ant-typography,
  h4.ant-typography,
  h5.ant-typography {
    font-weight: 500;
  }

  .ant-drawer-content {
    background: #333;
    color: white;

    .ant-drawer-header {
      border: 0;
    }

    .ant-drawer-close {
      color: white;
      width: 60px;
      height: 70px;
      top: 0;
      left: 0;
      position: absolute;
    }
  }

  .ant-dropdown-menu-item-disabled {
    opacity: 0.5;
  }

  .ant-drawer-content .ant-drawer-close {
    color: #333;
  }

  .mobile-menu.ant-drawer-content .ant-drawer-close {
    height: 53px;
  }
`;
