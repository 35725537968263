import {
  CreateGoalListRequest,
  CreateGoalRequest,
  GoalListToken,
  GoalToken,
  IGoal,
  IGoalList,
  UpdateGoalListRequest,
  UpdateGoalRequest,
} from '@shared/goals';
import { ImpactToken } from '@shared/impacts';
import { IUser, OrganizationToken, UserToken } from '@shared/types';
import { del, get, patch, post, put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function useListGoals(archived?: boolean, userToken?: UserToken) {
  const url = stringifyUrl({
    url: `/goals`,
    query: {
      archived: archived,
      userToken: userToken,
    },
  });

  return useApi<{ user: IUser; goals: IGoal[] }>(url);
}

export function listGoals(archived?: boolean, userToken?: UserToken) {
  const url = stringifyUrl({
    url: `/goals`,
    query: {
      archived: archived,
      userToken: userToken,
    },
  });

  return get<{ user: IUser; goals: IGoal[] }>(url);
}

export function useGoalLists(archived?: boolean) {
  const url = stringifyUrl({
    url: `/goals/lists`,
    query: {
      archived: archived,
    },
  });

  return useApi<IGoalList[]>(url);
}

export function goalLists(archived?: boolean) {
  const url = stringifyUrl({
    url: `/goals/lists`,
    query: {
      archived: archived,
    },
  });

  return get<IGoalList[]>(url);
}

export function useGoalList(goalListToken: GoalListToken) {
  const url = stringifyUrl({
    url: `/goals/lists/${goalListToken}`,
    query: {},
  });

  return useApi<IGoalList>(url);
}

export function goalList(goalListToken: GoalListToken) {
  const url = stringifyUrl({
    url: `/goals/lists/${goalListToken}`,
    query: {},
  });

  return get<IGoalList>(url);
}

export function useAssignedGoals(userToken?: UserToken) {
  const url = stringifyUrl({
    url: `/goals/assigned`,
    query: {
      userToken: userToken,
    },
  });

  return useApi<IGoal[]>(url);
}

export function assignedGoals(userToken?: UserToken) {
  const url = stringifyUrl({
    url: `/goals/assigned`,
    query: {
      userToken: userToken,
    },
  });

  return get<IGoal[]>(url);
}

export function useSelectGoalsSearch() {
  const url = stringifyUrl({
    url: `/goals/select-search`,
    query: {},
  });

  return useApi<IGoal[]>(url);
}

export function selectGoalsSearch() {
  const url = stringifyUrl({
    url: `/goals/select-search`,
    query: {},
  });

  return get<IGoal[]>(url);
}

export function useSelectGoalListsSearch() {
  const url = stringifyUrl({
    url: `/goals/select-list-search`,
    query: {},
  });

  return useApi<IGoalList[]>(url);
}

export function SelectGoalListsSearch() {
  const url = stringifyUrl({
    url: `/goals/select-list-search`,
    query: {},
  });

  return get<IGoalList[]>(url);
}

export function createGoalList(createGoalListRequest: CreateGoalListRequest) {
  return post<CreateGoalListRequest, IGoalList>(
    `/goals/lists`,
    createGoalListRequest,
  );
}

export function createGoal(createGoalRequest: CreateGoalRequest) {
  return post<CreateGoalRequest, IGoal>(`/goals`, createGoalRequest);
}

export function updateGoalList(
  goalListToken: GoalListToken,
  updateGoalListRequest: UpdateGoalListRequest,
) {
  return patch<UpdateGoalListRequest, IGoalList>(
    `/goals/lists/${goalListToken}`,
    updateGoalListRequest,
  );
}

export function addListGoal(
  goalListToken: GoalListToken,
  goalToken: GoalToken,
) {
  return post<{ goalToken: `g_${string}` }, IGoalList>(
    `/goals/lists/${goalListToken}/goals`,
    { goalToken: goalToken },
  );
}

export function removeListGoal(
  goalListToken: GoalListToken,
  goalToken: GoalToken,
) {
  return del(`/goals/lists/${goalListToken}/goals/${goalToken}`);
}

export function sortList(
  goalListToken: GoalListToken,
  sortedGoals: GoalToken[],
) {
  return put<{ sortedGoals: `g_${string}`[] }, IGoalList>(
    `/goals/lists/${goalListToken}/sort`,
    { sortedGoals: sortedGoals },
  );
}

export function archiveList(goalListToken: GoalListToken) {
  return post<{}, IGoalList>(`/goals/lists/${goalListToken}/archive`);
}

export function unarchiveList(goalListToken: GoalListToken) {
  return del(`/goals/lists/${goalListToken}/archive`);
}

export function updateGoal(
  updateGoalRequest: UpdateGoalRequest,
  goalToken: GoalToken,
) {
  return patch<UpdateGoalRequest, IGoal>(
    `/goals/${goalToken}`,
    updateGoalRequest,
  );
}

export function addSupportingGoal(
  goalToken: GoalToken,
  supportingGoal: GoalToken,
) {
  return post<{ supportingGoal: `g_${string}` }, void>(
    `/goals/${goalToken}/goals/supporting`,
    { supportingGoal: supportingGoal },
  );
}

export function archiveGoal(goalToken: GoalToken) {
  return post<{}, IGoal>(`/goals/${goalToken}/archive`);
}

export function unarchiveGoal(goalToken: GoalToken) {
  return del(`/goals/${goalToken}/archive`);
}

export function sendGoalActivityNotifications(
  organizationToken: OrganizationToken,
) {
  return post<{ organizationToken: `o_${string}` }>(
    `/goals/sendGoalActivityNotifications`,
    { organizationToken: organizationToken },
  );
}

export function useGoalsForEntry(impactToken: ImpactToken) {
  const url = stringifyUrl({
    url: `/goals/entries/${impactToken}`,
    query: {},
  });

  return useApi<IGoal[]>(url);
}

export function goalsForEntry(impactToken: ImpactToken) {
  const url = stringifyUrl({
    url: `/goals/entries/${impactToken}`,
    query: {},
  });

  return get<IGoal[]>(url);
}

export function useFindOneGoal(goalToken: GoalToken) {
  const url = stringifyUrl({
    url: `/goals/${goalToken}`,
    query: {},
  });

  return useApi<{ goal: IGoal }>(url);
}

export function findOneGoal(goalToken: GoalToken) {
  const url = stringifyUrl({
    url: `/goals/${goalToken}`,
    query: {},
  });

  return get<{ goal: IGoal }>(url);
}
