import { ISurveyDetails, SurveyCycleToken } from '@shared/surveys';
import { PageContent } from '@web/app/Page';
import { useApi } from '@web/common/useApi';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column } from '@web/components/layout';
import { Header3 } from '@web/components/typography';
import { Skeleton } from 'antd';
import { sortBy } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';

import { SurveyResponses } from './SurveyResponses';
import { ResponseSummaries } from './cycles/ResponseSummaries';

export const ViewSurveySummaryPage: React.FC = () => {
  const { surveyCycleToken } = useParams<{
    surveyCycleToken: SurveyCycleToken;
  }>();
  const { data: surveyDetails } = useApi<ISurveyDetails>(
    `/survey-cycles/${surveyCycleToken}/team`,
  );

  if (!surveyDetails?.surveyCycle) {
    return (
      <PageContent>
        <PageHeader
          title={surveyDetails?.surveyCycle.name ?? ''}
          mobileTitle={surveyDetails?.surveyCycle.name ?? ''}
          navigateBack
          defaultNavigateBackTo="/requests"
        />
        <Skeleton />
      </PageContent>
    );
  }

  const sortedQuestions = sortBy(
    surveyDetails.surveyCycle.questions,
    'position',
  );
  const respondents =
    surveyDetails.participants?.filter(
      (participant) => !!participant.submittedDate,
    ) ?? [];
  return (
    <PageContent>
      <PageHeader
        title={surveyDetails?.surveyCycle.name ?? ''}
        mobileTitle={surveyDetails?.surveyCycle.name ?? ''}
        navigateBack
        defaultNavigateBackTo="/requests"
      />
      <Column gap={24}>
        <Header3>Aggregate Responses</Header3>
        <Pane>
          <ResponseSummaries
            questions={sortedQuestions}
            summaries={surveyDetails.summaries}
            participantMap={surveyDetails.participantMap}
            averageRatingTrendMap={surveyDetails.averageRatingTrendMap}
            currentSurveyCycleToken={surveyCycleToken}
            totalParticipants={surveyDetails.totalParticipants}
          />
        </Pane>
        {respondents.length > 0 && (
          <>
            <Header3>Individual Responses</Header3>
            {respondents.map((participant) => (
              <SurveyResponses
                key={participant.token}
                participant={participant}
                questions={sortedQuestions}
                trendMap={
                  surveyDetails.participantRatingTrendMaps?.[
                    participant.userToken
                  ]
                }
                currentSurveyCycleToken={surveyCycleToken}
              />
            ))}
          </>
        )}
      </Column>
    </PageContent>
  );
};
