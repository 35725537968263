import { useSurveySummary } from '@client/SurveyCyclesClient';
import { SurveyCycleToken, SurveyParticipantToken } from '@shared/surveys';
import { UserMapItem } from '@shared/types';
import { Skeleton } from 'antd';
import { sortBy } from 'lodash';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { AdminPageContent } from '../../admin/AdminPageContent';
import { AdminSurveyContent } from './AdminSurveyContent';
import { ResponseSummaries } from './ResponseSummaries';

const AdminSurveyCycleResponsesPage: React.FC = () => {
  const { surveyCycleToken } = useParams<{
    surveyCycleToken: SurveyCycleToken;
  }>();
  const { data: response } = useSurveySummary(surveyCycleToken as any);
  if (!response) {
    return (
      <AdminPageContent>
        <Skeleton />
      </AdminPageContent>
    );
  }

  const participantMap: Record<SurveyParticipantToken, UserMapItem> = {};
  response.surveyCycle.participants?.forEach((participant) => {
    participantMap[participant.token] = participant.user;
  });
  const sortedQuestions = sortBy(response.surveyCycle.questions, 'position');
  return (
    <AdminSurveyContent surveyCycle={response.surveyCycle}>
      <ResponseSummaries
        questions={sortedQuestions}
        summaries={response.summaries}
        participantMap={participantMap}
        totalParticipants={response.totalParticipants}
      />
    </AdminSurveyContent>
  );
};

export default AdminSurveyCycleResponsesPage;
