import { useTeamAnalytics } from '@client/AnalyticsClient';
import {
  ITeamMetrics,
  METRIC_GREEN,
  METRIC_RED,
  METRIC_YELLOW,
  MetricType,
  MetricTypeDescriptions,
  MetricTypeLabels,
  TeamMetrics,
} from '@shared/analytics';
import { ITeam } from '@shared/types';
import { UserAvatar } from '@web/components/UserAvatar';
import { NoWrap, Row } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import { Avatar, Skeleton, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { BarGraphMetric } from './visualizations/BarGraphMetric';
import { LineGraphMetric } from './visualizations/LineGraphMetric';
import { PieChartMetric } from './visualizations/PieChartMetric';
import { ValuePercentMetric } from './visualizations/ValuePercentMetric';

export const TeamMetricsTable: React.FC<{
  teams: ITeam[];
  metricTypes: MetricType[];
}> = ({ teams, metricTypes }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { data: teamMetrics } = useTeamAnalytics(teams.map((t) => t.token));
  if (!teamMetrics) {
    return <Skeleton />;
  }

  const columns: ColumnsType<ITeam> = [
    {
      title: 'Team',
      dataIndex: 'manager',

      key: 'manager',
      fixed: 'left',
      render(manager) {
        return manager ? (
          <Row gap={6}>
            <UserAvatar user={manager} size={28} />{' '}
            <NoWrap>{manager.name}</NoWrap>
          </Row>
        ) : (
          <Row gap={6}>
            <Avatar
              size={28}
              style={{
                flexGrow: 0,
                flexShrink: 0,
                backgroundColor: '#aaa',
                border: 0,
                fontSize: 12,
              }}
            >
              -
            </Avatar>{' '}
            <NoWrap>No manager</NoWrap>
          </Row>
        );
      },
    },
  ];
  for (const metricType of metricTypes) {
    columns.push(createMetricColumn(metricType, teamMetrics));
  }

  return (
    <Table
      dataSource={teams}
      columns={columns}
      scroll={{ x: true }}
      onRow={(team) => ({
        onClick: () =>
          navigate(
            `/analytics/individuals?manager=${
              team.managerToken
            }&${searchParams.toString()}`,
          ),
        style: {
          cursor: 'pointer',
        },
      })}
    />
  );
};

const MetricLabel = styled(Text)`
  font-weight: 500;
`;

const CustomHeaderLabels: Partial<Record<MetricType, React.ReactNode>> = {
  [MetricType.ONE_ON_ONES_SCHEDULED]: (
    <MetricLabel>
      Scheduled <NoWrap>1-on-1s</NoWrap>
    </MetricLabel>
  ),
  [MetricType.ONE_ON_ONES_USERS_WITH_COMPLETED]: (
    <MetricLabel>
      Users Completing <NoWrap>1-on-1s</NoWrap>
    </MetricLabel>
  ),
  [MetricType.ONE_ON_ONES_ALIGNMENT]: (
    <MetricLabel>
      <NoWrap>1-on-1s</NoWrap> Alignment
    </MetricLabel>
  ),
  [MetricType.MANAGER_ONE_ON_ONES_SCHEDULED]: (
    <MetricLabel>
      Scheduled Manager <NoWrap>1-on-1s</NoWrap>
    </MetricLabel>
  ),
};

const createMetricColumn = (
  metricType: MetricType,
  teamMetrics: TeamMetrics,
): ColumnsType<ITeam>[number] => {
  const label = CustomHeaderLabels[metricType] ?? (
    <MetricLabel>{MetricTypeLabels[metricType]}</MetricLabel>
  );

  return {
    title: (
      <Tooltip title={MetricTypeDescriptions[metricType]}>{label}</Tooltip>
    ),
    key: metricType,
    align: 'center',
    render(_, team: ITeam) {
      return (
        <ViewTableMetric metrics={teamMetrics[team.token]} type={metricType} />
      );
    },
  };
};

export const ViewTableMetric: React.FC<{
  type: MetricType;
  metrics: ITeamMetrics;
}> = ({ type, metrics }) => {
  switch (type) {
    case MetricType.GOALS:
      return (
        <BarGraphMetric
          labeledMetrics={[
            {
              label: 'Off Track',
              value: metrics.goalsOffTrack,
              color: METRIC_RED,
            },
            {
              label: 'At Risk',
              value: metrics.goalsAtRisk,
              color: METRIC_YELLOW,
            },
            {
              label: 'On Track',
              value: metrics.goalsOnTrack,
              color: METRIC_GREEN,
            },
          ]}
          horizontal
          width={120}
          height={50}
        />
      );
    case MetricType.ONE_ON_ONES_SCHEDULED:
      return <Header3>{metrics.oneOnOnesScheduled}</Header3>;
    case MetricType.ONE_ON_ONES_USERS_WITH_COMPLETED:
      return (
        <ValuePercentMetric
          value={metrics.oneOnOnesUsersWithCompleted}
          total={metrics.userCount}
          itemName="user"
        />
      );
    case MetricType.JOURNAL_USER_WITH_ENTRIES:
      return (
        <ValuePercentMetric
          value={metrics.journalUsersWithEntries}
          total={metrics.userCount}
        />
      );
    case MetricType.JOURNAL_WEEKLY_ENTRIES:
      return (
        <LineGraphMetric
          labeledMetrics={metrics.journalWeeklyUsage}
          width={150}
          height={70}
          hideLabels
        />
      );
    case MetricType.ONE_ON_ONES_ALIGNMENT: {
      if (
        metrics.oneOnOnesAlignmentOnTrack +
          metrics.oneOnOnesAlignmentAtRisk +
          metrics.oneOnOnesAlignmentOffTrack ===
        0
      ) {
        return <Text>-</Text>;
      }
      return (
        <PieChartMetric
          data={[
            {
              name: 'On Track',
              value: metrics.oneOnOnesAlignmentOnTrack,
              color: METRIC_GREEN,
            },
            {
              name: 'At Risk',
              value: metrics.oneOnOnesAlignmentAtRisk,
              color: METRIC_YELLOW,
            },
            {
              name: 'Off Track',
              value: metrics.oneOnOnesAlignmentOffTrack,
              color: METRIC_RED,
            },
          ]}
          circleSize={50}
          hideLabels
        />
      );
    }
    case MetricType.JOURNAL_ALIGNMENT: {
      if (
        metrics.journalAlignmentOnTrack +
          metrics.journalAlignmentAtRisk +
          metrics.journalAlignmentOffTrack ===
        0
      ) {
        return <Text>-</Text>;
      }
      return (
        <PieChartMetric
          data={[
            {
              name: 'On Track',
              value: metrics.journalAlignmentOnTrack,
              color: METRIC_GREEN,
            },
            {
              name: 'At Risk',
              value: metrics.journalAlignmentAtRisk,
              color: METRIC_YELLOW,
            },
            {
              name: 'Off Track',
              value: metrics.journalAlignmentOffTrack,
              color: METRIC_RED,
            },
          ]}
          circleSize={50}
          hideLabels
        />
      );
    }
    case MetricType.MANAGER_ONE_ON_ONES_SCHEDULED:
      return (
        <ValuePercentMetric
          value={metrics.managerOneOnOnesScheduled}
          total={metrics.userCount}
          itemName="user"
        />
      );
    default:
      return null;
  }
};
