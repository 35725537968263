import {
  IAlignmentIndicator,
  TeamAlignmentIndicators,
} from '@shared/alignment';
import { IUser, OrganizationToken, UserToken } from '@shared/types';
import { del, get, post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function useAlignmentEnabled() {
  const url = stringifyUrl({
    url: `/alignment/enabled`,
    query: {},
  });

  return useApi<boolean>(url);
}

export function alignmentEnabled() {
  const url = stringifyUrl({
    url: `/alignment/enabled`,
    query: {},
  });

  return get<boolean>(url);
}

export function useAlignmentIndicators(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/alignment/user/${userToken}`,
    query: {},
  });

  return useApi<IAlignmentIndicator[]>(url);
}

export function alignmentIndicators(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/alignment/user/${userToken}`,
    query: {},
  });

  return get<IAlignmentIndicator[]>(url);
}

export function useTeamAlignmentIndicators(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/alignment/team/${userToken}`,
    query: {},
  });

  return useApi<TeamAlignmentIndicators>(url);
}

export function teamAlignmentIndicators(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/alignment/team/${userToken}`,
    query: {},
  });

  return get<TeamAlignmentIndicators>(url);
}

export function scheduleAlignmentIndicatorUpdates(
  organizationToken: OrganizationToken,
) {
  return post<{}, void>(`/alignment/organization/${organizationToken}/refresh`);
}

export function useEnrolledUsers() {
  const url = stringifyUrl({
    url: `/alignment/enrolled`,
    query: {},
  });

  return useApi<IUser[]>(url);
}

export function enrolledUsers() {
  const url = stringifyUrl({
    url: `/alignment/enrolled`,
    query: {},
  });

  return get<IUser[]>(url);
}

export function addEnrolledUsers(userToken: UserToken, includeTeam: boolean) {
  return post<{
    userToken: `u_${string}`;
    includeTeam: boolean;
  }>(`/alignment/enrolled`, {
    userToken: userToken,
    includeTeam: includeTeam,
  });
}

export function deleteEnrolledUser(userToken: UserToken) {
  return del(`/alignment/enrolled/${userToken}`);
}

export function onboardNewUsers() {
  return post<{}>(`/alignment/onboard`);
}
