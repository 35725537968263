import { ISession } from '@shared/types';
import { get, post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function requestLoginCode(email: string) {
  return post<{ email: string }>(`/auth/loginCode`, { email: email });
}

export function useInvalidSession() {
  const url = stringifyUrl({
    url: `/auth/invalid-session`,
    query: {},
  });

  return useApi<ISession>(url);
}

export function invalidSession() {
  const url = stringifyUrl({
    url: `/auth/invalid-session`,
    query: {},
  });

  return get<ISession>(url);
}

export function badRequest() {
  return post<{}, ISession>(`/auth/bad-request`);
}

export function useSession(buildtime?: number, buildhash?: string) {
  const url = stringifyUrl({
    url: `/auth/session`,
    query: {
      buildtime: buildtime,
      buildhash: buildhash,
    },
  });

  return useApi<ISession>(url);
}

export function session(buildtime?: number, buildhash?: string) {
  const url = stringifyUrl({
    url: `/auth/session`,
    query: {
      buildtime: buildtime,
      buildhash: buildhash,
    },
  });

  return get<ISession>(url);
}
