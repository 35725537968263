import { useTeams } from '@client/TeamClient';
import { MetricType, MetricTypeLabels } from '@shared/analytics';
import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { TabLink, Tabs } from '@web/components/Tabs';
import { Column, Row, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Select, Skeleton } from 'antd';
import { filter, sortBy, uniqBy } from 'lodash';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { MetricsOverview } from './MetricsOverview';
import { TeamMetricsTable } from './TeamMetricsTable';
import { UserMetricsTable } from './UserMetricsTable';

const defaultMetricTypes = [
  MetricType.JOURNAL_USER_WITH_ENTRIES,
  MetricType.JOURNAL_WEEKLY_ENTRIES,
  MetricType.JOURNAL_ALIGNMENT,
  MetricType.GOALS,
  MetricType.ONE_ON_ONES_SCHEDULED,
  MetricType.ONE_ON_ONES_USERS_WITH_COMPLETED,
  MetricType.ONE_ON_ONES_ALIGNMENT,
  MetricType.MANAGER_ONE_ON_ONES_SCHEDULED,
];

export enum AnalyticsTab {
  OVERVIEW = 'OVERVIEW',
  TEAMS = 'TEAMS',
  INDIVIDUALS = 'INDIVIDUALS',
}

export const AnalyticsPage: React.FC<{ tab: AnalyticsTab }> = ({ tab }) => {
  const { data: teams } = useTeams();
  const [searchParams, setSearchParams] = useSearchParams();
  // const visibleTeamTokens: TeamToken[] = searchParams.getAll('team') as any;
  const [metricsChanged, setMetricsChanged] = React.useState(false);
  // React.useEffect(() => {
  //   if (teams && visibleTeamTokens?.length === 0) {
  //     handleChooseTeams(teams.map((t) => t.token));
  //   }
  // }, [teams]);

  const metricTypesToShow: MetricType[] = searchParams.has('metric')
    ? (searchParams.getAll('metric') as any)
    : metricsChanged
    ? []
    : defaultMetricTypes;

  if (!teams) {
    return (
      <PageContent>
        <PageHeader title="Analytics" />
        <Skeleton />
      </PageContent>
    );
  }

  const handleChooseMetrics = (metricTypes: MetricType[]) => {
    setMetricsChanged(true);
    setSearchParams((prev) => {
      prev.delete('metric');
      for (const metricType of metricTypes) {
        prev.append('metric', metricType);
      }
      return prev;
    });
  };
  // const handleChooseTeams = (teamTokens: TeamToken[]) => {
  //   setSearchParams((prev) => {
  //     prev.delete('team');
  //     for (const teamToken of teamTokens) {
  //       prev.append('team', teamToken);
  //     }
  //     return prev;
  //   });
  // };

  // const teamsByToken = mapByToken(teams);
  // const visibleTeams = visibleTeamTokens.map((token) =>
  //   teamsByToken.get(token),
  // );
  const visibleTeams = teams;
  const visibleUsers = sortBy(
    filter(
      uniqBy(
        teams.flatMap((team) => team.members),
        'token',
      ),
    ),
    'name',
  );

  return (
    <PageContent>
      <PageHeader title="Analytics" />
      <Pane>
        <Row gap={12}>
          <Column gap={12} style={{ width: '100%', maxWidth: 800 }}>
            {/* <Column style={{ justifyContent: 'stretch' }}>
              <Text>Select teams:</Text>
              <Select
                mode="multiple"
                value={visibleTeamTokens}
                onChange={handleChooseTeams}
                allowClear
              >
                {teams.map((team) => (
                  <Select.Option value={team.token} key={team.token}>
                    {team.manager?.name ?? '-'}
                  </Select.Option>
                ))}
              </Select>
            </Column> */}
            <Column>
              <Text>Choose metrics:</Text>
              <Select
                mode="multiple"
                value={metricTypesToShow}
                onChange={handleChooseMetrics}
                allowClear
                placeholder="Select metrics you want to view"
              >
                {Object.values(MetricType).map((metricType) => (
                  <Select.Option value={metricType} key={metricType}>
                    {MetricTypeLabels[metricType]}
                  </Select.Option>
                ))}
              </Select>
            </Column>
            <Text>Showing data from the past 30 days</Text>
          </Column>
        </Row>
      </Pane>
      <Spacer />
      <Tabs>
        <TabLink to={`/analytics/overview?${searchParams.toString()}`}>
          Overview
        </TabLink>
        <TabLink to={`/analytics/teams?${searchParams.toString()}`}>
          Teams
        </TabLink>
        <TabLink to={`/analytics/individuals?${searchParams.toString()}`}>
          Individuals
        </TabLink>
      </Tabs>
      <Pane>
        <Spacer size={12} />
        {tab === AnalyticsTab.OVERVIEW && (
          <MetricsOverview
            teams={visibleTeams}
            metricTypes={metricTypesToShow}
          />
        )}
        {tab === AnalyticsTab.TEAMS && (
          <TeamMetricsTable
            teams={visibleTeams}
            metricTypes={metricTypesToShow}
          />
        )}
        {tab === AnalyticsTab.INDIVIDUALS && (
          <UserMetricsTable
            teams={visibleTeams}
            users={visibleUsers}
            metricTypes={metricTypesToShow}
          />
        )}
      </Pane>
    </PageContent>
  );
};
