import { IQuestion, QuestionToken } from '@shared/questions';
import { put } from '@web/common/api';
import { sortBy } from 'lodash';
import * as React from 'react';
import { ReactSortable } from 'react-sortablejs';

import { ISortableQuestion, QuestionItemRow } from './QuestionItemRow';

interface Props {
  questions?: IQuestion[];
  onEdit: (question: IQuestion) => void;
  onDelete: (questionToken: QuestionToken) => void;
  onClone: (questionToken: QuestionToken) => void;
  readonly?: boolean;
}

const hasChanged = (
  itemsA: ISortableQuestion[],
  itemsB: ISortableQuestion[],
) => {
  const idHashA = itemsA.map((item) => item.id).join(':');
  const idHashB = itemsB.map((item) => item.id).join(':');
  return idHashA !== idHashB;
};
export const QuestionsList: React.FC<Props> = ({
  questions = [],
  onEdit,
  onClone,
  onDelete,
  readonly,
}) => {
  const [sortedQuestionItems, setSortedQuestionItems] = React.useState<
    ISortableQuestion[]
  >(createSortableQuestionItems(questions));
  React.useEffect(() => {
    setSortedQuestionItems(createSortableQuestionItems(questions));
  }, [questions]);

  const sortQuestionItems = (newSortedQuestionItems: ISortableQuestion[]) => {
    if (!hasChanged(sortedQuestionItems, newSortedQuestionItems)) {
      return;
    }

    const newPositions: Record<QuestionToken, number> = {};
    for (let i = 0; i < newSortedQuestionItems.length; i++) {
      const token = newSortedQuestionItems[i].id;
      newPositions[token] = i;
    }
    void put(`/questions/positions`, newPositions);
    setSortedQuestionItems(newSortedQuestionItems);
  };

  return (
    <ReactSortable
      list={sortedQuestionItems}
      setList={sortQuestionItems}
      easing="cubic-bezier(0.55, 0, 1, 0.45)"
      animation={100}
      handle=".drag-anchor"
    >
      {sortedQuestionItems.map((item, i) => (
        <QuestionItemRow
          key={item.id}
          questionItem={item}
          position={i + 1}
          onClick={() => {
            const question = questions.find(
              (question) => question.token === item.id,
            );
            if (question) {
              onEdit(question);
            }
          }}
          onDelete={
            !readonly
              ? () => {
                  onDelete(item.id);
                }
              : undefined
          }
          onClone={
            !readonly
              ? () => {
                  onClone(item.id);
                }
              : undefined
          }
        />
      ))}
    </ReactSortable>
  );
};

const createSortableQuestionItems = (questions: IQuestion[]) => {
  const questionPositions: Record<QuestionToken, number> = questions.reduce(
    (positions, question) => {
      positions[question.token] = question.position;
      return positions;
    },
    {},
  );
  const questionItems: ISortableQuestion[] = questions.map((question) => ({
    id: question.token,
    text: question.text,
    type: question.type,
    position: question.position as any,
    required: question.required ?? false,
  }));
  return sortBy(questionItems, (item) => questionPositions[item.id]);
};
